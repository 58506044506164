<template>
  <div>
    <div class="text-center">
      <h4 v-if="courses_wrapper_id && coursesWrapper">
       {{ $getVisibleNames(
            "mesh.coursewrapper",
            false,
            "Envoltorio de Asignaturas"
        )}}: {{ coursesWrapper.name }}
      </h4>
      <h4 class="mt-4 mb-3" v-else>
        {{$getVisibleNames('manual.matriz_2', false, 'Matriz 2')}}: {{$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas')}}
        <span v-if="career">{{ " de " + career.name }} </span>
      </h4>
      <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <template v-if="allows_crud &&
            ((user_position &&[1, 2, 3].includes(user_position.position) && 
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              )) && tabIndex == 0">
              <b-button
                variant="primary"
                size="sm"
                class="mb-2 ml-3"
                @click="$bvModal.show('new-matter-modal')"
                >Agregar {{$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}}</b-button
              >
        </template>
          <b-button
            v-if="courses_wrapper_id==null"
            variant="primary"
            size="sm"
            class="mb-2 ml-3 noprint"
            @click="$bvModal.show('course-wrapper-modal')"
            >{{$getVisibleNames(
            'mesh.coursewrapper',
            false,
            'Envoltorio de Asignaturas'
          )}}</b-button>
          <b-button
            v-else
            variant="secondary"
            size="sm"
            class="mb-2 ml-3 noprint"
            @click="()=>{
              $router.push({
                  name: 'Matrix2View',
                  params: {
                    egress_profile_id: egress_profile_id,
                  },
                })
              }"  
            >Volver</b-button>
            <RemoveEPMattersComponent v-if="user && user.is_superuser && allows_crud && courses_wrapper_id==null" :egress_profile_id="egress_profile_id" :allows_crud="allows_crud">
            </RemoveEPMattersComponent>
        </div>
        <template v-if="courses_wrapper_id==null">
          <div>
            <b-button
            v-if="((institution && institution.internal_use_id == 'duoc_uc') || $debug_change_duoc)"
            variant="primary"
            class="btn btn-primary mr-3 mb-1 btn-download-excel noprint"
            @click="DownloadCompletedPMDXLS"
            >COMPLETITUD PMD XLS</b-button>
          <download-excel
            class="btn btn-primary mr-3 mb-1 btn-download-excel noprint"
            :fields="jsonFields"
            :fetch="fetchMatterXLS"
            :worksheet="`REPORTE DE ${$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas').toUpperCase()}`"
            :name="`Reporte de ${$getVisibleNames('mesh.egressprofilematter', true, 'Asignaturas')}.xls`"
          >
            {{$getVisibleNames(
                  "mesh.egressprofilematter",
                  true,
                  "Asignaturas"
                ).toUpperCase()}} XLS
          </download-excel>
          <b-button
          v-if="((institution && institution.internal_use_id == 'duoc_uc') || $debug_change_duoc)"
            variant="primary"
            class="btn btn-primary mr-3 mb-1 btn-download-excel noprint"
            @click="DownloadXLS"
            >MAPA FORMATIVO XLS</b-button>
          </div>
        </template>
      </div>
        <div class="container-v-select noprint">
          <span class="mt-1 ml-3 text-filter-formative-line"><strong>Filtrar por {{$getVisibleNames(
            'mesh.formationline',
            false,
            'Línea De Formación'
          )}}:</strong></span>
      <v-select
          class="mr-3 ml-1 mt-2 v-select-class "
          style="width: 100%"
          id="select-selected_formative_lines"
          :placeholder="`Seleccione uno o varios para filtrar.`"
          :options="formationLinesList"
          v-model="selected_formative_lines"
          :reduce="(formationLinesList) => formationLinesList.id"
          :selectable="(option) => !selected_formative_lines.includes(option.id)"
          @input="() => ( visible_formation_line = true )"
          label="name"
          track-by="id"
          size="sm"
          multiple
          :dropdown-should-open="dropdownShouldOpen"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontró resultados para <em>{{ search }}</em
              >.
            </template>
          </template>
        </v-select>
    </div>
    </div>
    <!-- Tabla -->
    <div class="table-responsive">
      <GenericBTable
        :key="visible_formation_line"
        :items="filterMatterList"
        :fields="fields"
        :filter="input_search"
        :filterCustom="filterCustom"
        :current-page="currentPage"
        :per-page="perPage"
        :pagination="50"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="isBusy"
        :sort-direction="sortDirection"
        @filtered="onFiltered"
        :delay_input="true"
        :show_pagination="true"
        :search_filter="true"
        :view_filter_by="true"
        :columns_display="true"
        :allows_crud="allows_crud &&
            ((user_position &&[1, 2, 3].includes(user_position.position) && 
              [1, 2, 3].includes(user.groups[0])) ||
              user.is_superuser ||
              $hasObjectPermission(
                'mesh.career',
                'change',
                egressProfile.career
              ))"
        :display_id="'profiles-list'"
        @emitChangeSearchField="changeInputSearch"
        @emitChangeFilterOn="changeFilterOn"
        :sticky-header="stickyHeaderSize"
      >
        <template #cell(name)="row">
          <div class="align-middle text-left" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">  
            <b-badge variant="none" pill class="code-matter mb-1" v-b-tooltip.v-secondary.noninteractive="`${$getVisibleNames('manual.matter_code',false,'Código')}`">{{row.item.code}}</b-badge>          
            <div class="text-left ml-4"></div>
          <div class="d-flex">
          <div>
            <b-link
              :to="{
                name: 'MatterProgramView',
                params: { matter_id: row.item.id },
              }"
              v-b-tooltip.v-secondary.noninteractive="`Ir al ${$getVisibleNames('manual.matter_program',false,'Programa de la Asignatura')}`"
              class="matter-link"
            >
            <template v-if="allows_crud">
                <template v-if="row.item.is_closed">
                  <b-icon icon="lock" scale="1"></b-icon>
                </template>
                <template v-else>
                  <b-icon icon="unlock" scale="1"></b-icon>
                </template>
              </template>
            {{ row.item.name.toUpperCase() }}
            </b-link>
            <template v-if="!row.item.use_matter_values">
              <template v-if="row.item.modalities.length>0">
                <b-badge :id="`modalities-badge-${row.item.id}`" class="modalities-badge"> 
                {{row.item.modalities? row.item.modalities.length: ''}}
                </b-badge>
                <ModalitiesPopover :object="row.item" :content="matterModalities(row.item.modalities)" :triggers="'hover'"></ModalitiesPopover>
              </template>
            </template>
            <template v-else>
              <template v-if="oldMatter(row.item) && oldMatter(row.item).modalities.length>0 ">
                <b-badge :id="`modalities-badge-${row.item.id}`" class="modalities-badge"> 
                {{oldMatter(row.item).modalities.length}}
                </b-badge>
                <ModalitiesPopover :object="row.item" :content="matterModalities(oldMatter(row.item).modalities)" :triggers="'hover'"></ModalitiesPopover>
              </template>
            </template>
            <div v-if="allows_crud &&
                  ((user_position &&[1, 2, 3].includes(user_position.position) && 
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egressProfile.career
                    ))" class="button-div">
              <button-edit
                v-can="'teaching.change_matter'"
                @click="$bvModal.show(`modal-edit-matter-${row.item.id}`)"
                v-b-tooltip.v-secondary.noninteractive="`Editar ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
              ></button-edit>
              <b-button
                variant="none"
                v-can="'teaching.change_matter'"
                v-if="((institution && institution.show_base_ra) || $debug_change_duoc) && matterCompetenceIds(row.item).length > 0 && !isClosedOldMatter(row.item)"
                @click="$bvModal.show(`modal-copy-study-unit-${row.item.id}`)"
                v-b-tooltip.v-secondary.noninteractive="`Vincular ${$getVisibleNames('teaching.basera', true, 'RA Base')} a ${row.item.name}`"
              >
                <template v-if="row.item.is_integrative">
                  <b-icon icon="ui-checks"></b-icon>
                </template>
                <template v-else><b-icon icon="ui-radios"></b-icon></template>
              </b-button>
              
              <button-add
                v-can="'teaching.change_matter'"
                v-if="!isClosedOldMatter(row.item)"
                @click="openModalStudyUnit(row.item.id)" 
                v-b-tooltip.v-secondary.noninteractive="`Adicionar ${$getVisibleNames('teaching.ramicro',false,'RA Micro')}`"
              ></button-add>
            </div>
              <b-modal
                :id="`modal-new-study-unit-${row.item.id}`"
                :title="`Crear ${$getVisibleNames(
                        'teaching.ramicro',
                        false,
                        'RA Micro'
                      )}`"
                size="lg"
                hide-footer
                no-enforce-focus
                @hide="hideModalRaMacro()"
              >
                  <StudyUnitForm
                    :egress_profile_matter_id="row.item.id"
                    :matter_id="row.item.matter"
                    :show_temp_competence_unit="true"
                    @created="$bvModal.hide(`modal-new-study-unit-${row.item.id}`)"
                    @close_modal="$bvModal.hide(`modal-new-study-unit-${row.item.id}`)"
                  ></StudyUnitForm>
              </b-modal>
              <b-modal
                :id="`modal-copy-study-unit-${row.item.id}`"
                :title="`Vincular ${$getVisibleNames('teaching.basera', false, 'RA Base')} a ${row.item.name}`"
                size="lg"
                hide-footer
              >
                <SelectedRaBase
                :matrix2_view="true"
                :competences_ids="matterCompetenceIds(row.item)"
                :egress_profile_id="egress_profile_id"
                :egress_profile_matter="row.item"
                :matter_id="row.item.matter"
                @closeModal="$bvModal.hide(`modal-copy-study-unit-${row.item.id}`)"
              ></SelectedRaBase>
              </b-modal>
              <b-modal
                :id="`modal-edit-matter-${row.item.id}`"
                hide-footer
                :title="`Editar ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
                size="xl"
                no-enforce-focus
              >
                <MatterForm
                  :Matter="row.item"
                  :egress_profile_id="row.item.egress_profile"
                  :show_delete_button="true"
                  @update="slotUpdatedMatter"
                  @updated="slotUpdatedMatter"
                ></MatterForm>
              </b-modal>
              </div>
            </div>
          </div>
        </template>
        <template #cell(competences)="row">
          <div class="content-div" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
            <p
              v-for="competence in matterCompetences(row.item)"
              :key="`competence-${row.item.matter}-${competence.id}`"
              :title="competence.type?competenceTypes.find((x) => x.id == competence.type).name:''"
            >
              <section class="d-flex">
                <strong class="mt-1 competence-order">{{competence.order}}.{{cycleOrder(competence.cycle)}}</strong>   
                  <SentenceContainer 
                  class="mt-1"
                  :unique_id="row.item.id+'-'+competence.id+'-'+competence.full_sentence"
                    :Sentence="competence">
                  </SentenceContainer>
              </section>
            </p> <MatterCompetencesModal :matter="row.item" :egressProfile="egressProfile" 
            :open_modal="open_competence_modal == row.item.id ? true : false"
            :allows_crud="!row.item.is_closed ? allows_crud &&
                  ((user_position &&[1, 2, 3].includes(user_position.position) && 
                    [1, 2, 3].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.career',
                      'change',
                      egressProfile.career
                    )) : false"></MatterCompetencesModal>
          </div>
        </template>
        <template #cell(temp_competence_unit)="row">
          <CompetenceUnitCell
          :profileMatter="row.item"
          :matter_id="row.item.matter"
          :allows_crud="!isClosedOldMatter(row.item) ? allows_crud &&
                    ((user_position &&[1, 2, 3].includes(user_position.position) && 
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egressProfile.career
                      )) : false"
          ></CompetenceUnitCell>
        </template>
        <template #cell(ra_macro)="row"><div class="ra-macro-div" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
          <section v-if="getRaMacro(row.item.matter)"><SentenceContainer :Sentence="getRaMacro(row.item.matter)">
              </SentenceContainer></section>
              <div v-if="!isClosedOldMatter(row.item) ? allows_crud &&
                          ((user_position &&[1, 2, 3].includes(user_position.position) && 
                            [1, 2, 3].includes(user.groups[0])) ||
                            user.is_superuser ||
                            $hasObjectPermission(
                              'mesh.career',
                              'change',
                              egressProfile.career
                            )) : false"> 
              <button-edit v-if="getRaMacro(row.item.matter)" v-b-tooltip.v-secondary="`Editar ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}`" v-can="'teaching.change_ramacro'"
              @click="openModalCreate(row.item.id)"></button-edit>
              <button-add v-if="!getRaMacro(row.item.matter)" v-b-tooltip.v-secondary="`Crear ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}`" v-can="'teaching.change_ramacro'"
              @click="openModalEdit(row.item.id)"></button-add>
              <b-modal
              :id="`modal-new-ra-macro-${row.item.id}`"
              :title="`Crear ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}.`"
              size="lg"
              hide-footer
              no-enforce-focus              
              @hide="hideModalRaMacro"
            >
              <RAMacroForm
                :matter_id="row.item.matter"
                @created="$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)"
                @create="$bvModal.hide(`modal-new-ra-macro-${row.item.id}`)"
              ></RAMacroForm>
            </b-modal>
            <b-modal
              :id="`modal-edit-ra-macro-${row.item.id}`"
              :title="`Editar ${$getVisibleNames('teaching.ramacro', false, 'RA Macro')}.`"
              size="lg"
              hide-footer
              no-enforce-focus
              @hide="hideModalRaMacro"
            >
              <RAMacroForm
                :matter_id="row.item.matter"
                :RAMacro="getRaMacro(row.item.matter)"
                @update="$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)"
                @updated="$bvModal.hide(`modal-edit-ra-macro-${row.item.id}`)"
              ></RAMacroForm>
            </b-modal>
              </div>
              </div>
              </template>
        <template #cell(ra_micro)="row">
          <div class="ra-micro-container" :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
            <template v-if="studyUnitList(row.item).length>0">                
              <template v-for="(study_unit) in studyUnitList(row.item)">
                <StudyUnitCell
                v-if="study_unit"
                :egress_profile_matter_id="row.item.id"
                :study_unit_id="study_unit.id"
                :key="`study-unit-cell-${study_unit.id}`"
                :matter_id="row.item.matter"
                :allows_crud="!isClosedOldMatter(row.item) ? allows_crud &&
                    ((user_position &&[1, 2, 3].includes(user_position.position) && 
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egressProfile.career
                      )) : false"
                class="ra-micro-content"
              ></StudyUnitCell>
              </template>
            </template>
            <template v-else>
              N/A
            </template>
          </div>
        </template>
        <template #cell(methodological_strategies)="row">
          <div :class="[ row.item.id == last_matter? 'new-matter-container' : '' ]">
          <MethodologicalStrategiesCell
            :ProfileMatter="row.item"
            :allows_crud="!isClosedOldMatter(row.item) ? allows_crud &&
                    ((user_position &&[1, 2, 3].includes(user_position.position) && 
                      [1, 2, 3].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        egressProfile.career
                      )) : false"
          ></MethodologicalStrategiesCell>
          </div>
          </template>
      </GenericBTable>
    </div>
    <!-- Modals -->
    <b-modal
      id="new-matter-modal"
      :title="`Crear ${$getVisibleNames('mesh.egressprofilematter',false,'Asignatura')}`"
      size="xl"
      @hide="confirmClose"
      hide-footer
    >
      <MatterForm
        :egress_profile_id="egress_profile_id"
        :courses_wrapper_id="courses_wrapper_id"
        @created="slotCreatedMatter"
        @close_modal="slotCreatedMatter"
        @change_matter_form="() => (change_matter_form = true)"
      ></MatterForm>
    </b-modal>
    <b-modal
      id="course-wrapper-modal"
      :title="`${$getVisibleNames('mesh.coursewrapper',false,'Envoltorio de Asignaturas')}`"
      size="xl"
      hide-footer
    >
      <CoursesWrapperView 
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
      @closeModal="$bvModal.hide('course-wrapper-modal')"></CoursesWrapperView>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import { URL } from "@/utils/globals";

export default {
  name: "Matrix2",
  components: {
    vSelect,
    // StepGenericModal: () => import("@/components/reusable/StepGenericModal"),
    SelectedRaBase: () => import("@/components/reusable/SelectedRaBase"),
    // EvaluationProcedureCell: () => import("./EvaluationProcedureCell"),
    MatterCompetencesModal: () => import("./MatterCompetencesModal"),
    RemoveEPMattersComponent: () => import("./RemoveEPMattersComponent"),
    MethodologicalStrategiesCell: () =>
      import("./MethodologicalStrategiesCell"),
    StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    // MatterTable: () => import("./MatterTable"),
    RAMacroForm: () => import("./RAMacroForm"),
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
    StudyUnitCell: () => import("./StudyUnitCell"),
    MatterForm: () => import("./MatterForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    ModalitiesPopover: () => import("@/components/reusable/ModalitiesPopover"),
    CoursesWrapperView: () =>
      import("@/components/mesh/Matrix2/CoursesWrapper/CoursesWrapperView"),
    CompetenceUnitCell: () =>
      import(
        "@/components/teaching/MatterProgram/Components/CompetenceUnit/CompetenceUnitCell"
      ),
    // RaBase: () => import("@/components/reusable/RaBase"),
  },
  props: {
    isBusy: {
      type: Boolean,
      required: true,
    },
    egress_profile_id: {
      type: Number,
      required: true,
    },
    courses_wrapper_id: {
      default: null,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // selected_cycle_id: this.cycle_id,
      input_search: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [5, 10, 15, { value: 1000, text: "Mostrar Todos" }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filterOn: [],
      window_height: 100,
      loading: false,
      tabIndex: 0,
      last_matter: null,
      open_competence_modal: null,
      item_created_id: null,
      // generic_table_key: 0,
      matters_reports: null,
      prevent_close_modal: false,
      change_matter_form: false,
      current_item: [],
      selected_formative_lines: [],
      timeoutID: null,
      visible_formation_line: false,
      stickyHeaderSize: "600px",
      // profile_ra_base: [],
    };
  },
  computed: {
    ...mapGetters({
      user_access: names.USER_POSITION,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      cycles: names.CYCLES,
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      matters: names.MATTERS,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      profile_cycles: names.PROFILE_CYCLES,
      competenceTypes: names.COMPETENCE_TYPES,
      learningResults: names.LEARNING_RESULTS,
      profile_competences: names.PROFILE_COMPETENCES,
      studyUnits: names.STUDY_UNITS,
      formationLines: names.FORMATION_LINES,
      methodologicalStrategies: names.METHODOLOGICAL_STRATEGIES,
      competences: names.COMPETENCES,
      ra_macros: names.RA_MACROS,
      modalities: names.MODALITIES,
      ra_bases: names.RA_BASES,
      courses_wrapper: names.COURSES_WRAPPER,
      competence_units: names.COMPETENCE_UNITS,
    }),
    egressProfile() {
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    coursesWrapper() {
      if (!this.courses_wrapper_id) return null;
      return this.courses_wrapper.find((x) => x.id == this.courses_wrapper_id);
    },
    coursesWrapperList() {
      return this.courses_wrapper.filter(
        (x) => x.egress_profile == this.egress_profile_id
      );
    },
    wrapperMatterIds() {
      let list = [];
      this.coursesWrapperList.forEach((x) => {
        if (this.courses_wrapper_id == null)
          list = list.concat(x.egress_profile_matters);
        else if (
          x.id == this.courses_wrapper_id &&
          this.courses_wrapper_id != null
        )
          list = list.concat(x.egress_profile_matters);
      });
      return [...new Set(list)];
    },
    matterList() {
      let list = [];
      if (this.courses_wrapper_id == null)
        list = this.egressProfileMatters.filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            !this.wrapperMatterIds.includes(x.id)
          //  &&
          // x.cycle == this.selected_cycle_id
        );
      else if (this.courses_wrapper_id != null)
        list = this.egressProfileMatters.filter(
          (x) =>
            x.egress_profile == this.egress_profile_id &&
            this.wrapperMatterIds.includes(x.id)
        );
      list = list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        return 0;
      });
      return list.map((x) => {
        return { ...x, name: this.getMatterName(x.matter) };
      });
    },
    matterListFormationLinesIds() {
      return [...new Set(this.matterList.map((x) => x.formation_line))];
    },
    currentItemListIds() {
      let list = this.current_item;
      if (this.selected_formative_lines.length > 0)
        list = list.filter((x) =>
          this.selected_formative_lines.includes(x.formation_line)
        );
      return list;
    },
    formationLinesList() {
      if (!this.career) return [];
      return this.formationLines.filter(
        (x) =>
          x.faculties.includes(this.career.faculty) &&
          this.matterListFormationLinesIds.includes(x.id)
      );
    },
    filterMatterList() {
      let list = this.matterList;
      if (this.selected_formative_lines.length > 0)
        list = list.filter((x) =>
          this.selected_formative_lines.includes(x.formation_line)
        );
      return list;
    },
    fields() {
      return [
        {
          key: "order",
          label: "N°",
          display_column: true,
          sortable: true,
          thClass: "align-middle",
          thStyle: {
            width: "1%",
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
          tdClass: "align-middle",
        },
        {
          key: "name",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ).toUpperCase(),
          class: "text-center",
          display_column: true,
          sortable: true,
          thClass: "align-middle",
          thStyle: {
            width: "13%",
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
          tdClass: "align-middle",
        },
        {
          key: "competences",
          class: "text-center",
          label: String(
            this.$getVisibleNames(
              "mesh.competence",
              true,
              "Competencias"
            ).toUpperCase()
          ),
          display_column: true,
          // sortable: true,
          thClass: "align-middle",
          tdClass: "align-middle",
          thStyle: {
            width: "25%",
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
        },
      ]
        .concat(
          this.institution && this.institution.internal_use_id == "duoc_uc"
            ? [
                {
                  key: "formation_line",
                  class: "text-center",
                  label: String(
                    this.$getVisibleNames(
                      "mesh.formationline",
                      false,
                      "Línea De Formación"
                    ).toUpperCase()
                  ),
                  display_column: this.visible_formation_line,
                  sortable: true,
                  tdClass: "align-middle",
                  thClass: "align-middle",
                  formatter: (value) => this.getFormationLine(value),
                  thStyle: {
                    width: "10%",
                    background: "var(--primary-color) !important",
                    position: "sticky !important",
                  },
                },
              ]
            : []
        )
        .concat(
          this.institution && this.institution.internal_use_id == "ciisa_uss"
            ? [
                {
                  key: "temp_competence_unit",
                  label: String(
                    this.$getVisibleNames(
                      "teaching.tempcompetenceunit",
                      false,
                      "Unidad de competencia"
                    ).toUpperCase()
                  ),
                  display_column: true,
                  hidden_key: true,
                  tdClass: "align-middle",
                  thClass: "align-middle",
                  thStyle: {
                    width: "15%",
                    background: "var(--primary-color) !important",
                    position: "sticky !important",
                  },
                },
              ]
            : []
        )
        .concat(
          this.institution && this.institution.show_ra_macro
            ? [
                {
                  key: "ra_macro",
                  class: "text-center",
                  label: String(
                    this.$getVisibleNames(
                      "teaching.ramacro",
                      false,
                      "RA MACRO"
                    ).toUpperCase()
                  ),
                  display_column: true,
                  hidden_key: true,
                  tdClass: "align-middle",
                  thClass: "align-middle",
                  thStyle: {
                    width: "15%",
                    background: "var(--primary-color) !important",
                    position: "sticky !important",
                  },
                },
              ]
            : []
        )
        .concat([
          {
            key: "ra_micro",
            class: "text-center",
            label: String(
              this.$getVisibleNames(
                "teaching.ramicro",
                false,
                "RA MICRO"
              ).toUpperCase()
            ),
            display_column: true,
            hidden_key: true,
            thStyle: {
              width: "17%",
              background: "var(--primary-color) !important",
              position: "sticky !important",
            },
            thClass: "align-middle",
            tdClass: "align-middle",
          },
          {
            key: "methodological_strategies",
            class: "text-center",
            label:
              this.$getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              ).toUpperCase() + " MÍNIMAS",
            display_column: true,
            thStyle: {
              width: "15%",
              background: "var(--primary-color) !important",
              position: "sticky !important",
            },
            tdClass: "align-middle",
          },
        ]);
    },
    jsonFields() {
      let json_fields = {};
      if (this.matters_reports != null)
        for (
          let index = 0;
          index < this.matters_reports.labels.length;
          index++
        ) {
          const label = `<table><th style='display:table-cell;background-color: #9BC2E6; vertical-align: middle; border: .5pt solid black' >${this.matters_reports.labels[index]}</th></table>`;
          json_fields[label] = this.matters_reports.labels[index];
        }

      return json_fields;
    },
    itemsXLS() {
      let list = [];
      if (this.matters_reports != null) {
        this.matters_reports.datasets.forEach((elements) => {
          let row = {};
          for (let index = 0; index < elements.data.length; index++) {
            const label = this.matters_reports.labels[index];
            const data = elements.data[index];
            row[label] = data;
          }
          list.push(row);
        });
      }
      return list;
    },
  },
  methods: {
    dropdownShouldOpen(VueSelect) {
      if (this.formationLinesList.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    async fetchMatterXLS() {
      const response = await this.$restful.Post(
        `/mesh/egress_profile_matter_report/`,
        {
          egress_profile: this.egress_profile_id,
          egress_profile_matter_ids: this.currentItemListIds.map((x) => x.id),
        }
      );
      this.matters_reports = response;
      let list = [];
      if (response != null) {
        for (const elements of response.datasets) {
          let row = {};
          for (let index = 0; index < elements.data.length; index++) {
            const label = response.labels[index];
            const data = elements.data[index];
            row[label] = data;
          }
          list.push(row);
        }
      }
      return list;
    },
    insertStoreOpenAi(egress_profile_matter_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          egress_profile_id: this.egress_profile_id,
          egress_profile_matter_id: egress_profile_matter_id,
          intelligence_option: 5,
        });
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [5],
          disabled_selected_egress_profile: true,
        });
      });
    },
    insertStoreOpenAiStudyUnit(egress_profile_matter_id) {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          egress_profile_id: this.egress_profile_id,
          egress_profile_matter_id: egress_profile_matter_id,
          intelligence_option: 2,
        });
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options:
            this.institution && this.institution.internal_use_id == "duoc_uc"
              ? [2, 7]
              : [2],
          disabled_selected_egress_profile: true,
          disabled_selected_egress_profile_matter: true,
        });
      });
    },
    hideModalRaMacro() {
      this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
      this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);

      this.$nextTick(() => {
        this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
          egress_profile_id: this.egress_profile_id,
          intelligence_option: 2,
        });
        this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
          visible_intelligence_options: [2, 4, 5, 7],
          egress_profile_matter_id: null,
          competence_unit_id: null,
          disabled_selected_egress_profile: true,
        });
      });
    },
    openModalCreate(egress_profile_matter_id) {
      this.insertStoreOpenAi(egress_profile_matter_id);
      this.$bvModal.show(`modal-new-ra-macro-${egress_profile_matter_id}`);
    },
    openModalEdit(egress_profile_matter_id) {
      this.insertStoreOpenAi(egress_profile_matter_id);
      this.$bvModal.show(`modal-edit-ra-macro-${egress_profile_matter_id}`);
    },
    openModalStudyUnit(egress_profile_matter_id) {
      this.insertStoreOpenAiStudyUnit(egress_profile_matter_id);
      this.$bvModal.show(`modal-new-study-unit-${egress_profile_matter_id}`);
    },
    updateStickyHeaderSize() {
      const viewportHeight = window.innerHeight;
      const newStickyHeaderSize = viewportHeight * 1 + "px"; // Por ejemplo, 100% de la altura del viewport
      this.stickyHeaderSize = newStickyHeaderSize;
    },
    confirmClose(bvModalEvt) {
      if (
        bvModalEvt.trigger === "headerclose" ||
        bvModalEvt.trigger === "event"
      ) {
        this.change_matter_form = false;
        return;
      }
      if (!this.prevent_close_modal && this.change_matter_form) {
        bvModalEvt.preventDefault();
        this.$bvModal
          .msgBoxConfirm(
            "¿Estás seguro de que quieres cerrar? Los datos no guardados se perderán.",
            {
              title: "Confirmación Necesaria",
              size: "md",
              buttonSize: "md",
              okVariant: "warning",
              okTitle: "Sí, cerrar",
              cancelTitle: "Cancelar",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
              headerClass: "text-black",
              header: {
                title: "Confirmación Necesaria",
                class: "text-center",
              },
            }
          )
          .then((value) => {
            if (value) {
              this.prevent_close_modal = true;
              this.$bvModal.hide(bvModalEvt.componentId);
              this.$nextTick(() => {
                this.change_matter_form = false;
                this.prevent_close_modal = false;
              });
            }
          });
      }
    },
    getFormationLine(formation_line_id) {
      const formation_line = this.formationLines.find(
        (x) => x.id === formation_line_id
      );
      return formation_line ? formation_line.name : "N/A";
    },
    changeInputSearch(input_search) {
      if (input_search.trim() === "") {
        this.current_item = this.matterList;
      }
      this.input_search = input_search;
    },
    changeFilterOn(filter_on) {
      this.filterOn = filter_on;
    },
    onFiltered(filteredItems) {
      if (this.selected_formative_lines.length == 0)
        this.current_item = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    DownloadXLS() {
      this.$restful
        .Post(`/teaching/excel_report_formative_map/`, {
          egress_profile: this.egress_profile_id,
          egress_profile_matter_ids: this.currentItemListIds.map((x) => x.id),
        })
        .then((response) => {
          // URL + `/teaching/excel_report_formative_map/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    DownloadCompletedPMDXLS() {
      this.$restful
        .Post(`/mesh/excel_report_duoc_completed_by_pmd/`, {
          egress_profile: this.egress_profile_id,
          egress_profile_matter_ids: this.currentItemListIds.map((x) => x.id),
        })
        .then((response) => {
          // URL + `/teaching/excel_report_formative_map/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    getRaMacro(matter_id) {
      const ra = this.ra_macros.find((x) => x.matter == matter_id);
      return ra ? ra : null;
    },
    getRaMacroSentence(matter_id) {
      const ra = this.ra_macros.find((x) => x.matter == matter_id);
      return ra ? ra.sentence : "";
    },
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.egressProfile) return "-";
      let cycleOrder = this.profile_cycles.find(
        (x) => x.cycle == cycle_id && x.egress_profile == this.egressProfile.id
      );
      return cycleOrder ? cycleOrder.order : "-";
    },
    // filterStudyUnits(matter) {
    //   const OldMatter = this.oldMatter(matter);
    //   return (
    //     OldMatter &&
    //     this.studyUnits.some(
    //       (study_unit) =>
    //         OldMatter.study_units.includes(study_unit.id) &&
    //         (this.$filtered(study_unit.name, this.input_search) ||
    //           this.$filtered(study_unit.full_sentence, this.input_search))
    //     )
    //   );
    // },
    filterMethodolicalStategies(matter) {
      const OldMatter = this.oldMatter(matter);
      if (!OldMatter) return false;
      return this.methodologicalStrategies.some(
        (strategy) =>
          OldMatter.methodological_strategies.includes(strategy.id) &&
          this.$filtered(strategy.name, this.input_search)
      );
    },
    getCompetenceFilter(competences_ids) {
      return this.competences.some(
        (competence) =>
          competences_ids.includes(competence.id) &&
          this.$filtered(competence.full_sentence, this.input_search)
      );
    },
    filterCustom(row) {
      const filterOnEmpty = this.filterOn.length === 0;
      let matterNameFiltered = null;
      let competenceFiltered = null;
      let raMacroFiltered = null;
      // let studyUnitsFiltered = null;
      let methodolicalStrategiesFiltered = null;
      let formationLineFiltered = null;
      if (filterOnEmpty || this.filterOn.includes("matter"))
        matterNameFiltered = this.$filtered(row.name, this.input_search);
      if (filterOnEmpty || this.filterOn.includes("competences"))
        competenceFiltered = this.getCompetenceFilter(row.competences);
      if (filterOnEmpty || this.filterOn.includes("ra_macro"))
        raMacroFiltered = this.$filtered(
          this.getRaMacroSentence(row.matter),
          this.input_search
        );
      // if (filterOnEmpty || this.filterOn.includes("ra_micro"))
      //   studyUnitsFiltered = this.filterStudyUnits(row);
      if (filterOnEmpty || this.filterOn.includes("methodological_strategies"))
        methodolicalStrategiesFiltered = this.filterMethodolicalStategies(row);
      if (filterOnEmpty || this.filterOn.includes("formation_line"))
        formationLineFiltered = this.$filtered(
          this.getFormationLine(row.formation_line),
          this.input_search
        );
      const matterCondition =
        matterNameFiltered && this.filterOn.includes("matter");
      const competencesCondition =
        competenceFiltered && this.filterOn.includes("competences");
      const raMacroCondition =
        raMacroFiltered && this.filterOn.includes("ra_macro");
      // const studyUnitsCondition =
      //   studyUnitsFiltered && this.filterOn.includes("ra_micro");
      const methodolicalStrategiesCondition =
        methodolicalStrategiesFiltered &&
        this.filterOn.includes("methodological_strategies");
      const formationLineCondition =
        formationLineFiltered && this.filterOn.includes("formation_line");
      return (
        ((matterNameFiltered ||
          competenceFiltered ||
          raMacroFiltered ||
          formationLineFiltered ||
          // studyUnitsFiltered ||
          methodolicalStrategiesFiltered) &&
          filterOnEmpty) ||
        matterCondition ||
        competencesCondition ||
        raMacroCondition ||
        formationLineCondition ||
        // studyUnitsCondition ||
        methodolicalStrategiesCondition
      );
    },
    studyUnitList(matter) {
      return this.studyUnits
        .filter((x) => x.matter == matter.matter)
        .map((x) => {
          const comopetence_unit = this.competence_units.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: comopetence_unit
              ? comopetence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    oldMatter(matter) {
      return this.matters.find((x) => x.id == matter.matter);
    },
    isClosedOldMatter(epm_matter) {
      const matter = this.matters.find((x) => x.id == epm_matter.matter);
      if (!matter) return true;
      else {
        if (matter.is_closed) return true;
        else return false;
      }
    },
    matterCompetenceIds(matter) {
      const competence_ids = this.matterCompetences(matter).map(
        (x) => x.competence
      );
      return competence_ids;
    },
    matterCompetences(matter) {
      let list = [];
      if (
        !matter &&
        !this.egressProfile &&
        !this.profile_competences &&
        !this.competences
      )
        return list;
      matter.competences.forEach((element) => {
        let get_competence = this.competences.find((x) => x.id == element);
        if (get_competence) {
          let instance = this.profile_competences.find(
            (x) =>
              x.competence == get_competence.id &&
              x.egress_profile == this.egressProfile.id
          );
          if (instance) {
            list.push({
              id: instance.id,
              order: instance.order,
              egress_profile: instance.egress_profile,
              competence: instance.competence,
              cycle: instance.cycle,
              action: get_competence.action,
              content: get_competence.content,
              condition: get_competence.condition,
              context: get_competence.context,
              full_sentence: get_competence.full_sentence,
              type: get_competence.type,
              verb: get_competence.verb,
              sentence: get_competence.sentence,
              name: get_competence.name,
              selected: get_competence.selected,
            });
          }
        }
      });
      return list.sort(function (a, b) {
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
        if (a.cycle > b.cycle) return 1;
        if (a.cycle < b.cycle) return -1;
        return 0;
      });
    },
    slotUpdatedMatter(matter) {
      this.$bvModal.hide(`modal-edit-matter-${matter.id}`);
    },
    slotCreatedMatter(matter) {
      this.$bvModal.hide("new-matter-modal");
      this.last_matter = matter.id;
      this.open_competence_modal = matter.id;
      // this.generic_table_key += 1;
      this.item_created_id = matter.id;
      clearTimeout(this.timeoutID);
      this.timeoutID = setTimeout(() => {
        this.open_competence_modal = null;
        this.last_matter = null;
      }, 1000);
      // window.scrollTo(0,document.body.scrollHeight)
      // if (this.matterList.length > 1) {
      //   setTimeout(() => {
      //     window.scrollTo(
      //       0,
      //       document.body.scrollHeight || document.documentElement.scrollHeight
      //     );
      //   }, 100);
      // }
    },
    matterModalities(modalities_ids) {
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
  },
  created() {
    this.current_item = this.matterList;
    this.$store.dispatch(names.FETCH_MODALITIES);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateStickyHeaderSize);
  },
  watch: {
    egressProfile: function () {
      if (this.egressProfile)
        this.$store.dispatch(names.FETCH_CAREER, this.egressProfile.career);
    },
    matterList(newVal) {
      this.current_item = newVal;
    },
  },
  mounted() {
    this.totalRows = this.matterList.length;
    this.updateStickyHeaderSize();
    window.addEventListener("resize", this.updateStickyHeaderSize);
  },
};
</script>

<style scoped>
.v-select-class >>> .vs__selected {
  background-color: #6c757d98;
  color: #fff !important;
}
.container-v-select {
  display: flex;
  justify-content: right;
  align-items: center;
}
.text-filter-formative-line {
  white-space: nowrap;
}
.code-matter {
  border: 1px solid black;
  padding-top: 5px;
  font-weight: bold;
  font-size: 8pt;
  letter-spacing: 0.3px;
  margin-left: 15px;
}
html {
  scroll-behavior: smooth;
}
.table-container {
  padding-top: 0px;
  margin-top: 0px;
  z-index: 0;
}
.table-container-max-height {
  padding-top: 0px;
  margin-top: 0px;
  height: 100% !important;
}
.center-div-child {
  position: absolute;
  /* background: rgb(251, 251, 251); */
  padding: 0.6em 0;
  width: 50vh;
  top: 25%;
  left: 50%;
  border-radius: 3px;
  transform: translate(-50%, -50%);
}
th,
thead {
  position: sticky !important;
  top: 0 !important;
  margin-top: 0 !important;
  border-top: 0 solid black;
  width: 100vh;
}
th {
  position: sticky !important;
  z-index: 100;
  top: 0;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-size: var(--thirth-font-size);
  margin: 0px 0px;
  padding: 0px 20px;
  text-align: center;
  background: var(--secondary-color);
  color: white;
  border: 1px solid #000;
}

.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.competence-order {
  font-size: var(--secondary-font-size);
}
.bottom-row {
  background: var(--secondary-color);
}
.new-matter-container {
  /* padding: 0.25em;
  width: 100% !important;
  height: max-content !important; */
  animation: blinker 1s linear 7;
}
.table-responsive >>> .table td {
  vertical-align: middle;
}
.form-group >>> .label-matter {
  font-size: var(--secondary-font-size);
}
/* .label {
  font-size: var(--secondary-font-color);
} */

.modalities-badge,
.certification-badge {
  font-size: 0.6rem;
  vertical-align: top;
  padding: 0.15rem 0.2rem;
  cursor: default !important;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.ra-macro-div {
  margin-left: 0em;
  /* word-break: break-all; */
  hyphens: auto;
}
.ra-micro-container {
  display: flex !important;
  flex-direction: column;
}
.ra-micro-content {
  border-bottom: 0.1px solid var(--secondary-color);
  margin: 0 !important;
  margin-top: 0.4em !important;
  min-height: fit-content;
}
.ra-micro-content:last-child {
  border-bottom: none;
}
.matter-link {
  font-size: 10pt;
}
.btn-download-excel {
  min-width: 154px;
}
@media (max-width: 800px) {
  thead {
    width: 90vh;
    right: 0 !important;
  }
  .center-div-child {
    top: 30%;
  }
  .content-div {
    hyphens: auto;
  }
}
.pointer-class {
  cursor: pointer;
}

@media print {
  .table-responsive >>> .table {
    overflow: hidden !important;
  }
}
</style>